.calendar-booking {
  border: none !important;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  margin: auto !important;
  /* background-color: #e6ca6b !important; */
}

.calendar-day {
  background: #e6ca6b !important;
  height: 50px !important;
  width: 40px !important;
  border-radius: 50% !important;
}

.circle-day {
  height: 50px !important;
  width: 40px !important;
  border-radius: 50% !important;
}

.container-table {
  /* height: 400px; */
  width: 100%;
  overflow-x: scroll; /* or auto */
  overflow-y: hidden;
}

.required-field::after {
  content: ' *';
  color: red;
}
