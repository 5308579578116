.photo-profile-Icon {
  background: url('./photo_profile.png');
  background-size: 50px 50px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 50px !important;
  width: 50px !important;
}
.background-login-image {
  background: url('../logo/bgfix.png') center bottom / cover;
  height: 100%;
}
.round {
  border-radius: 50% !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
input[type='number'] {
  -moz-appearance: textfield !important;
}

.badge-check {
  background: url('./badge_check.png');
  background-size: 15px 15px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 15px !important;
  width: 15px !important;
}

.spinner {
  background: url('./spinner.png');
  background-size: 15px 15px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 15px !important;
  width: 15px !important;
}

.speak {
  background: url('./speak.png');
  background-size: 25px 25px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px !important;
  width: 25px !important;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-ignore-onclickoutside {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.warningDatePicker {
  border-color: #e0b4b4 !important;
  background: #fff6f6 !important;
  color: #9f3a38 !important;
}

.scrollableMenu {
  width: 450px;
  height: 240px;
  overflow-x: hidden;
  overflow-y: scroll;
}
